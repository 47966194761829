import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';
import LoginPage from 'src/pages/auth/jwt/login';
import { mainRoutes } from './main';
import { dashboardRoutes } from './dashboard';
import { componentsRoutes } from './components';

// const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <AuthClassicLayout>
          <LoginPage />
        </AuthClassicLayout>
      ),
    },

    ...dashboardRoutes,

    ...mainRoutes,

    ...componentsRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
