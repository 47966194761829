import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  auth: {
    login: 'usnondani/login-user',
  },
  api: {
    region: 'usnondani/district-wise-summary',
    factory: 'usnondani/factory-wise-summary',
    home: 'usnondani/dashboard-summary-factory',
    acceptReg: 'usnondani/approved-sugar-registrationsv1',
    otherReg: 'usnondani/otherapproved-sugar-registrationsv1',
    dabbleReg: 'usnondani/double-sugar-registrationsv1',
    waitingCutting: 'usnondani/remaining-cane-cuttingv1',
    completeCutting: 'usnondani/cane-cutting-listv1',
    appCaneReg: 'usnondani/app-registration',
    appCanReject: 'usnondani/app-rejected-registrations',
    appAcceptReg: 'usnondani/app-accepted-registrations',
    acceptRejectReg: 'usnondani/accept-reject-nondani',
    completeCaneCutting: 'usnondani/complete-sugar-cutting',
    completeMulCaneCutting: 'usnondani/complete-sugar-cutting-list',
    updateFarmer: 'usnondani/modify-farmer',
    uploadExcel: 'usnondani/add-approved-registrations',
    otherExcelUpload: 'usnondani/other-approved-registrations',
  },
};
