import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
// routes
import { usePathname } from 'src/routes/hooks';
import HomeColors from 'src/sections/home/home-color-presets';
import HomeLooking from 'src/sections/home/home-looking-for';
import HomeMinimal from 'src/sections/home/home-minimal';
import AboutTestimonials from 'src/sections/home/home-testimonial';
//
import Footer from './footer';
import Header from './header';

// ----------------------------------------------------------------------

export default function MainLayout({ children }) {
  const pathname = usePathname();
  const theme = useTheme();

  const isLight = theme.palette.mode === 'light';

  const isHome = pathname === '/';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      <Header />

      <Box
        component="main"
        sx={{
          background: isLight ? '#fff' : 'transparent',
          flexGrow: 1,
          ...(!isHome && {
            pt: { xs: 8, md: 10 },
          }),
        }}
      >
        {children}
      </Box>
      <HomeMinimal />
      <HomeColors />
      <HomeLooking />
      <AboutTestimonials />
      <Footer />
    </Box>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node,
};
