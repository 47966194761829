import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// OVERVIEW
const DashboardPage = lazy(() => import('src/pages/dashboard/view/dashboard'));
const AcceptCaneRegPage = lazy(() => import('src/pages/dashboard/view/acceptReg'));
const OtherCaneReg = lazy(() => import('src/pages/dashboard/view/otherCaneReg'));
const DabbleCaneRegPage = lazy(() => import('src/pages/dashboard/view/dabbleReg'));
const WaitingCaneCuttingPage = lazy(() => import('src/pages/dashboard/view/waitingCaneCutting'));
const CompleteCaneCuttingPage = lazy(() => import('src/pages/dashboard/view/completeCaneCutting'));
const MobileCaneRegPage = lazy(() => import('src/pages/dashboard/view/mobileCaneReg'));

const MobileCaneRejectRegPage = lazy(() => import('src/pages/dashboard/view/mobileCaneRejectReg'));
const MobileCaneAcceptRegPage = lazy(() => import('src/pages/dashboard/view/mobileAcceptCaneReg'));
const FactoryWiseRegPage = lazy(() => import('src/pages/dashboard/view/factoryWiseReg'));
const RegionWiseRegPage = lazy(() => import('src/pages/dashboard/view/regionWiseReg'));
// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'sugar-cane',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { path: 'dashboard/:year?', element: <DashboardPage />, index: true },
      { path: 'accept-registration/:year?', element: <AcceptCaneRegPage /> },
      { path: 'dabble-registration/:year?', element: <DabbleCaneRegPage /> },
      { path: 'other-registration/:year?', element: <OtherCaneReg /> },
      { path: 'waiting-cutting/:year?', element: <WaitingCaneCuttingPage /> },
      { path: 'complete-cutting/:year?', element: <CompleteCaneCuttingPage /> },
      { path: 'mobile-cane-registration/:year?', element: <MobileCaneRegPage /> },
      { path: 'mobile-reject-registration/:year?', element: <MobileCaneRejectRegPage /> },
      { path: 'mobile-accept-registration/:year?', element: <MobileCaneAcceptRegPage /> },
      { path: 'factory-wise/:year?', element: <FactoryWiseRegPage /> },
      { path: 'region-wise/:year?', element: <RegionWiseRegPage /> },
    ]
  },
];
