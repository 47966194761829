import PropTypes from 'prop-types';
import { m } from 'framer-motion';
import { sub } from 'date-fns';
// @mui
import Masonry from '@mui/lab/Masonry';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// utils
import { fDate } from 'src/utils/format-time';
// _mock
import { _mock } from 'src/_mock/_mock';
// theme
import { bgBlur, bgGradient, hideScroll } from 'src/theme/css';
// components
import Iconify from 'src/components/iconify';
import { MotionViewport, varFade } from 'src/components/animate';

// ----------------------------------------------------------------------
const _testimonials = [
    {
        name: 'Vaibhav Konde',
        postedDate: sub(new Date(), { days: 1, hours: 1 }),
        ratingNumber: 4.4,
        avatarUrl: _mock.image.avatar(14),
        content: `Excellent Work! Thanks a lot!`,
    },
    {
        name: 'Ashok Bhat',
        postedDate: sub(new Date(), { days: 2, hours: 2 }),
        ratingNumber: 5,
        avatarUrl: _mock.image.avatar(2),
        content: `I have been a loyal customer of Deavnet Solutions for several years, and their commitment to providing exceptional service and top-quality products continues to impress me.`,
    },
    {
        name: 'Yogesh Jadhav',
        postedDate: sub(new Date(), { days: 3, hours: 3 }),
        ratingNumber: 4.3,
        avatarUrl: _mock.image.avatar(11),
        content: `Customer support is really fast and helpful the design of this theme is looks amazing also the code is very clean and readable really good job !`,
    },
    {
        name: 'Prakash Taware',
        postedDate: sub(new Date(), { days: 4, hours: 4 }),
        ratingNumber: 5,
        avatarUrl: _mock.image.avatar(4),
        content: `Supportive and approachable management, Work-life balance & Collaborative work environment. The leadership team genuinely cares about employees' growth and well-being, fostering a strong sense of trust and respect.`,
    },
    {
        name: 'Nilesh Jagadale',
        postedDate: sub(new Date(), { days: 5, hours: 5 }),
        ratingNumber: 5,
        avatarUrl: _mock.image.avatar(5),
        content: `Nice Company and Good Work Life Balance Good and Young People with lot of energy, The culture is very cool and the people are supportive. The management is good too no politics fully focused on work. Flexible timings best leave policy.`,
    },
    {
        name: 'Pradip Bhosale',
        postedDate: sub(new Date(), { days: 6, hours: 6 }),
        ratingNumber: 4.4,
        avatarUrl: _mock.image.avatar(13),
        content: `Since implementing this web application into our business processes, we've seen a 40% increase in productivity and a significant reduction in errors. Our team loves using it, and it has become an integral part of our daily operations.`,
    },
    {
        name: 'Kiran Godase',
        postedDate: sub(new Date(), { days: 7, hours: 7 }),
        ratingNumber: 4.2,
        avatarUrl: _mock.image.avatar(1),
        content: `IT Prodigy is a highly skilled and responsive IT support company. They have been managing our network and infrastructure for over two years now, and we couldn't be happier with their services.`,
    },
    {
        name: 'Pratiksha Kokate',
        postedDate: sub(new Date(), { days: 8, hours: 8 }),
        ratingNumber: 4.2,
        avatarUrl: _mock.image.avatar(0),
        content: `The culture is very cool and the people are supportive. The management is good too no politics fully focused on work.`,
    },
];

export default function AboutTestimonials() {
    const theme = useTheme();

    const mdUp = useResponsive('up', 'md');

    const renderLink = (
        <Button color="primary" endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}>
            Read more testimonials
        </Button>
    );

    const renderDescription = (
        <Box
            sx={{
                maxWidth: { md: 360 },
                textAlign: { xs: 'center', md: 'unset' },
            }}
        >
            <m.div variants={varFade().inUp}>
                <Typography variant="overline" sx={{ color: 'common.white', opacity: 0.48 }}>
                    Testimonials
                </Typography>
            </m.div>

            <m.div variants={varFade().inUp}>
                <Typography variant="h2" sx={{ my: 3, color: 'common.white' }}>
                    Who love <br />
                    my work
                </Typography>
            </m.div>

            <m.div variants={varFade().inUp}>
                <Typography sx={{ color: 'common.white' }}>
                    Our goal is to create a product and service that you’re satisfied with and use it every
                    day. This is why we’re constantly working on our services to make it better every day and
                    really listen to what our users has to say.
                </Typography>
            </m.div>

            {!mdUp && (
                <Box
                    component={m.div}
                    variants={varFade().inUp}
                    sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}
                >
                    {renderLink}
                </Box>
            )}
        </Box>
    );

    const renderContent = (
        <Box
            sx={{
                py: { md: 10 },
                height: { md: 1 },
                ...(mdUp && {
                    ...hideScroll.y,
                }),
            }}
        >
            <Masonry spacing={3} columns={{ xs: 1, md: 2 }} sx={{ ml: 0 }}>
                {_testimonials.map((testimonial) => (
                    <m.div key={testimonial.name} variants={varFade().inUp}>
                        <TestimonialCard testimonial={testimonial} />
                    </m.div>
                ))}
            </Masonry>
        </Box>
    );

    return (
        <Box>
            <Box
                sx={{
                    ...bgGradient({
                        color: alpha(theme.palette.grey[900], 0.9),
                        imgUrl: '/assets/images/about/testimonials.jpg',
                    }),
                    overflow: 'hidden',
                    height: { md: 840 },
                    py: { xs: 10, md: 0 },
                }}
            >
                <Container component={MotionViewport} sx={{ position: 'relative', height: 1 }}>
                    <Grid
                        container
                        spacing={3}
                        alignItems="center"
                        justifyContent={{ xs: 'center', md: 'space-between' }}
                        sx={{ height: 1 }}
                    >
                        <Grid xs={10} md={4}>
                            {renderDescription}
                        </Grid>

                        <Grid
                            xs={12}
                            md={7}
                            lg={6}
                            alignItems="center"
                            sx={{
                                height: 1,
                            }}
                        >
                            {renderContent}
                        </Grid>
                    </Grid>

                    {mdUp && (
                        <Box
                            component={m.div}
                            variants={varFade().inUp}
                            sx={{ bottom: 60, position: 'absolute' }}
                        >
                            {renderLink}
                        </Box>
                    )}
                </Container>
            </Box>
        </Box>
    );
}

// ----------------------------------------------------------------------

function TestimonialCard({ testimonial, sx, ...other }) {
    const theme = useTheme();

    const { name, ratingNumber, postedDate, content, avatarUrl } = testimonial;

    return (
        <Stack
            spacing={3}
            sx={{
                ...bgBlur({
                    color: theme.palette.common.white,
                    opacity: 0.08,
                }),
                p: 3,
                borderRadius: 2,
                color: 'common.white',
                ...sx,
            }}
            {...other}
        >
            <Iconify icon="mingcute:quote-left-fill" width={40} sx={{ opacity: 0.48 }} />

            <Typography variant="body2">{content}</Typography>

            <Rating value={ratingNumber} readOnly size="small" />

            <Stack direction="row">
                <Avatar alt={name} src={avatarUrl} sx={{ mr: 2 }} />

                <ListItemText
                    primary={name}
                    secondary={fDate(postedDate)}
                    primaryTypographyProps={{
                        typography: 'subtitle2',
                        mb: 0.5,
                    }}
                    secondaryTypographyProps={{
                        typography: 'caption',
                        color: 'inherit',
                        sx: { opacity: 0.64 },
                    }}
                />
            </Stack>
        </Stack>
    );
}

TestimonialCard.propTypes = {
    sx: PropTypes.object,
    testimonial: PropTypes.object,
};
